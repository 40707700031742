<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <div class="d-flex flex-column align-items-center">
      <b-img
        :src="qrCode"
        :blank="!qrCode"
        width="200"
        height="200"
        fluid
        alt="user-qr-code"
      />
      <vx-input
        v-if="secretKey"
        v-model="secretKey"
        label="Secret key"
        name="secret"
        readonly
      >
        <template #append>
          <vx-button-copy
            v-b-tooltip.hover.top="'Copy secret key'"
            variant="transparent"
            size="sm"
            :text-to-copy="secretKey"
          />
        </template>
      </vx-input>
    </div>
  </b-overlay>
</template>

<script>
import { admins, useResource } from '@/services/resources'
import { onBeforeMount, ref } from '@vue/composition-api'
import VxInput from '@/components/form/VxInput'
import { VxButtonCopy } from '@/components/buttons'
import { BImg, BOverlay } from 'bootstrap-vue'

export default {
  name: 'TwoFa',
  components: {
    VxInput,
    VxButtonCopy,
    BImg,
    BOverlay
  },
  props: {
    admin: {
      type: Object,
      required: true
    }
  },
  setup ({ admin }) {
    const {
      callRequest,
      loading
    } = useResource(admins.twoFa)

    const qrCode = ref(null)
    const secretKey = ref(null)

    const getTwoFaInfo = async () => {
      const [err, res] = await callRequest({ urlParams: { id: admin.id } })
      if (err) return

      const {
        data: {
          qr_code,
          secret
        }
      } = res

      qrCode.value = qr_code
      secretKey.value = secret
    }

    onBeforeMount(() => {
      getTwoFaInfo()
    })

    return {
      qrCode,
      secretKey,
      loading
    }
  }
}
</script>
