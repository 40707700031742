<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <admin-details
          :admin="admin"
          @update="updateAdmin"
        />
      </b-col>
    </b-row>
    <b-row v-if="admin.role">
      <b-col>
        <permissions
          :edit-mode="false"
          :role="admin.role"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import AdminDetails from '@/views/admins/admin/AdminDetails'
import Permissions from '@/views/admins/role/Permissions'
import { ref } from '@vue/composition-api'

export default {
  name: 'Admin',
  components: {
    Permissions,
    AdminDetails,
    BRow,
    BCol
  },
  props: {
    dataResolverResponse: {
      type: Object,
      default: null
    }
  },
  setup ({ dataResolverResponse }) {

    const admin = ref(null)

    const updateAdmin = (value) => {
      admin.value = {
        ...value,
        role_id: value.role ? value.role.id : null
      }
    }

    updateAdmin(dataResolverResponse)

    return {
      admin,
      updateAdmin
    }
  }
}
</script>
