<template>
  <div>
    <vx-table-simple
      :items="items"
      :columns="columns"
      stacked
    >
      <template #title>
        Details
      </template>

      <template #actions>
        <div>
          <vx-button
            variant="primary"
            class="mr-1"
            :can="canTwoFa"
            @click="openTwoFa"
          >
            2FA info
          </vx-button>
          <vx-button
            variant="primary"
            class="mr-1"
            :can="canUpdatePassword"
            @click="openPasswordEditor"
          >
            Update password
          </vx-button>
          <vx-button
            variant="primary"
            :can="canUpdateAdmin"
            @click="openAdminEditor"
          >
            Update admin
          </vx-button>
        </div>
      </template>
    </vx-table-simple>
    <b-modal
      v-model="adminEditorIsOpen"
      title="Update admin"
      hide-footer
      title-tag="h4"
    >
      <admin-editor
        :admin="admin"
        @submit="handleAdminSubmit"
      />
    </b-modal>
    <b-modal
      v-model="passwordEditorIsOpen"
      title="Update password"
      hide-footer
      title-tag="h4"
    >
      <password-editor
        :admin="admin"
        @submit="handlePasswordSubmit"
      />
    </b-modal>
    <b-modal
      v-model="twoFaIsOpen"
      title="2FA info"
      hide-footer
      title-tag="h4"
    >
      <two-fa :admin="admin" />
    </b-modal>
  </div>
</template>

<script>
import { VxTableSimple } from '@/components/table'
import { toArray } from '@/services/utils'
import { VxButton } from '@/components/buttons'
import { computed } from '@vue/composition-api'
import AdminEditor from '@/views/admins/admin/AdminEditor'
import { useModalEditor } from '@/services/modal'
import { admins, useResource } from '@/services/resources'
import PasswordEditor from '@/views/admins/admin/PasswordEditor'
import TwoFa from '@/views/admins/admin/TwoFa'

export default {
  name: 'AdminDetails',
  components: {
    TwoFa,
    PasswordEditor,
    AdminEditor,
    VxTableSimple,
    VxButton
  },
  props: {
    admin: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const items = computed(() => toArray(props.admin))

    const columns = [
      { key: 'login' },
      { key: 'first_name' },
      { key: 'last_name' },
      { key: 'email' },
      { key: 'phone' },
      { key: 'default_page' },
      {
        key: 'role.title',
        label: 'Role'
      }
    ]

    const { can: canUpdateAdmin } = useResource(admins.update)

    const {
      modalIsOpen: adminEditorIsOpen,
      openModal: openAdminEditor,
      closeModal: closeAdminEditor
    } = useModalEditor()

    const handleAdminSubmit = (res) => {
      emit('update', res)
      closeAdminEditor()
    }

    const { can: canUpdatePassword } = useResource(admins.updatePassword)

    const {
      modalIsOpen: passwordEditorIsOpen,
      openModal: openPasswordEditor,
      closeModal: closePasswordEditor
    } = useModalEditor()

    const handlePasswordSubmit = () => {
      closePasswordEditor()
    }

    const { can: canTwoFa } = useResource(admins.updatePassword)

    const {
      modalIsOpen: twoFaIsOpen,
      openModal: openTwoFa
    } = useModalEditor()

    return {
      items,
      columns,

      canUpdateAdmin,
      adminEditorIsOpen,
      openAdminEditor,
      handleAdminSubmit,

      canUpdatePassword,
      passwordEditorIsOpen,
      openPasswordEditor,
      handlePasswordSubmit,

      canTwoFa,
      twoFaIsOpen,
      openTwoFa
    }
  }
}
</script>
