<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.password"
      :rules="rules.password"
      name="password"
      type="password"
      label="Password"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { VxForm, VxInput } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { admins, passDataToResource } from '@/services/resources'
import { reactive } from '@vue/composition-api/dist/vue-composition-api'
import { apiDefaultValidators } from '@/services/form'

export default {
  name: 'PasswordEditor',
  components: {
    VxForm,
    VxInput,
    VxButton
  },
  props: {
    admin: {
      type: Object,
      required: true
    }
  },
  setup ({ admin }, { emit }) {
    const formResource = passDataToResource(admins.updatePassword, {
      requestParams: {
        urlParams: { id: admin.id }
      }
    })

    const formValue = reactive({
      login: '',
      email: '',
      phone: '',
      first_name: '',
      last_name: '',
      default_page: '',
      role_id: ''
    })

    const { maxTinyTextLength } = apiDefaultValidators
    const rules = {
      password: {
        required: true,
        max: maxTinyTextLength,
        min: 6
      }
    }

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res.data)
      }
    }

    return {
      formValue,
      formResource,
      rules,
      handleSubmit
    }
  }
}
</script>
